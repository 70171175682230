import type { TokenData } from '~/shared/types';

export function getAccessToken(): string {
  return getTokenFromCookies('access_token');
}

export function getRefreshToken(): string {
  return getTokenFromCookies('refresh_token');
}

function getTokenFromCookies(tokenName: string): string {
  const token = document.cookie.split(';').find((c) => c.trim().startsWith(`${tokenName}=`));
  if (token) {
    return token.split('=')[1];
  }
  return '';
}

export function setToken(data: TokenData): void {
  const accessExpireAt = new Date(Date.now() + data.expires_in);
  setTokenToCookies('access_token', data.access_token, accessExpireAt);
  const refreshExpireAt = new Date(Date.now());
  refreshExpireAt.setMonth(refreshExpireAt.getMonth() + 1);
  setTokenToCookies('refresh_token', data.refresh_token, refreshExpireAt);
}

function setTokenToCookies(tokenName: string, value: string, expire: Date): void {
  document.cookie = `${tokenName}=${value}; Secure; SameSite=None; expires=${expire.toUTCString()}`;
}
