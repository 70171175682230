import type { TokenData, User } from '~/shared/types';
import { getAccessToken } from '~/utils/tokens';

export async function logout(token: string): Promise<void> {
  await fetch('user/api/oidc/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateEmail(token: string, email: string): Promise<boolean> {
  const response = await fetch('/user/api/account/update-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      email: email,
    }),
  });
  return response.ok;
}

export async function _updatePassword(token: string, newPassword: string): Promise<void> {
  await fetch('/user/api/account/update-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      password: newPassword,
    }),
  });
}

export async function resetPassword(clientId: string, clientSecret: string, username: string): Promise<void> {
  const response = await fetch('user/api/account/reset-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      client_secret: clientSecret,
      username: username,
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function updateResetPassword(
  clientId: string,
  clientSecret: string,
  token: string,
  password: string,
): Promise<void> {
  const response = await fetch('user/api/account/update-reset-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      client_secret: clientSecret,
      token: token,
      password: password,
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function _refreshToken(
  clientId: string,
  clientSecret: string,
  refreshToken: string,
): Promise<TokenData | null> {
  const response = await fetch('user/api/oidc/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: clientId,
      client_secret: clientSecret,
    }),
  });
  if (!response.ok) {
    return null;
  }
  return response.json();
}

export async function impersonate(body: {
  client_id: string;
  client_secret: string;
  username: string;
}): Promise<TokenData | null> {
  const response = await fetch('user/api/oidc/impersonate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getAccessToken()}`,
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    return null;
  }
  return response.json();
}

export async function createToken(
  clientId: string,
  clientSecret: string,
  username: string,
  password: string,
): Promise<TokenData | null> {
  const response = await fetch('user/api/oidc/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'password',
      client_id: clientId,
      client_secret: clientSecret,
      username: username,
      password: password,
    }),
  });
  if (!response.ok) {
    return null;
  }
  return response.json();
}

export async function getUserInfo(token: string): Promise<User> {
  const response = await fetch('/user/api/oidc/userinfo', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
}
